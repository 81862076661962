import {Link} from "react-router-dom";
import start from "../assets/start_b.webp";
import React from "react";
import '../css/onboarding.css';
import config from "../config";
import UserData from "../session";
import InitialLoading from "./Load";

export class Onboarding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: null,
            years: 0,
            background: null,
            number: 0,
            percent: 0,
            reward: 0,
            page: 0,
        };
    }

    componentDidMount() {
        fetch(config.server_api + 'onboard?' + UserData.getData())
            .then(res => {
                return res.json();
            }).then(res => {
                this.setState({
                    title: res.title,
                    years: res.years,
                    background: res.background,
                    number: res.number,
                    percent: res.percent,
                    reward: res.reward,
                    page: 1
                });
            }).catch(err => {
                window.location.href = '/404';
            });
    }

    render () {
        console.log(this.state.background)
        if(this.state.page === 1) {
            return (<div className="onboarding-page">
                    <div className="steps-onboarding">
                        <span className="steps-onboarding__step step-one active"></span>
                        <span className="steps-onboarding__step step-two"></span>
                    </div>
                    <div className="status-member">
                        <h1>{this.state.title}</h1>
                        <p className="lead">You've joined Telegram</p>
                        <div className="status-member__time">
                            <span className="when" style={{background: `url(${this.state.background})`}}>{this.state.years}</span>
                            <span className="when-text">years ago</span>
                        </div>
                        <div className="status-member__user-info">
                            <span>Your account number is #{this.state.number}</span>
                            <span>You're in the Top {this.state.percent}% Telegram users &#128293;</span>
                        </div>
                    </div>
                    <span onClick={() => {
                        this.setState({page: 2})
                    }}
                          className="btn btn-full-width btn-primary">Continue</span>
                </div>
            );
        } else if (this.state.page === 2){
            return (<div className="onboarding-page">
                <div className="steps-onboarding">
                    <span className="steps-onboarding__step step-one active"></span>
                    <span className="steps-onboarding__step step-two active"></span>
                </div>
                <div className="reward-member">
                    <h1> You are amazing! </h1>
                    <p className="lead">Here is your $ANGEL reward</p>
                    <div className="reward-member__time">
                        <img src={start} className="angel-logo" alt="logo"/>
                        <span className="reward-text">{this.state.reward} $ANGEL!</span>
                    </div>
                    <div className="reward-member__user-info">
                        <span>Thanks for your time on Telegram &#129304;</span>
                    </div>
                </div>
                <Link to="/" className="btn btn-full-width btn-primary">Continue</Link>
            </div>)
        } else {
            return (<InitialLoading/>)
        }
    }
}