let UserData = (function() {
    let telegram_data = "";

    let getData = function() {
        return telegram_data;
    };

    let setData = function(data) {
        if(data.length < 5) {
            window.location.href = '/404';
        }
        telegram_data = data;
    };

    return {
        setData: setData,
        getData: getData,
    }

})();

export default UserData;