import React from "react";
import {Link} from "react-router-dom";
import {FaChartSimple, FaHouse, FaMessage, FaUsers} from "react-icons/fa6";


export default function Navigation() {

    const isCurrent = (url) => {
        return window.location.pathname === url ? ' current': '';
    }

    return (
        <nav className="navigation-tabs">
            <Link to="/" className={"nav-item" + isCurrent('/')}> <FaHouse/> Home </Link>
            <Link to="/leaderboard" className={"nav-item" + isCurrent('/leaderboard')}> <FaChartSimple/> Leaderboard </Link>
            <Link to="/angels" className={"nav-item" + isCurrent('/angels')}> <FaMessage/> Angels </Link>
            <Link to="/invite-friends" className={"nav-item" + isCurrent('/invite-friends')}> <FaUsers/> Friends </Link>
        </nav>
    );

}
