import React from "react";


export default function YourRewards(props) {
    const list = props.rewards.map((item, index) =>
        <div className="rewards__item" key={index}>
            <span className="rewards__item-icon">
                {/*<FaReact/>*/}
                <img src={item.icon} alt=''/>
            </span>
            <span className="rewards__item-text">
              <span className="rewards__item-text__name">{item.title}</span>
            </span>
            <span className="rewards__item-amount">{item.amount}</span>
        </div>
    )

    return (
        <div className="rewards">
          <span className="rewards__title">
            <span className="rewards__title-section">Your Rewards</span>
            <span className="rewards__title-token">$ANGEL</span>
          </span>
            {list}
        </div>
    );
}
