import './App.css';
// // ICONOS
//https://react-icons.github.io/react-icons/icons/fa6/

import {BrowserRouter, Route, Routes} from 'react-router-dom';
//https://react-hot-toast.com/
// SWIPER IMPORTS
import React from 'react';
// Import Swiper React components

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import {Friends} from "./pages/Friends";
import Angels from "./pages/Angels";
import {Leaderboard} from "./pages/Leaderboard";
import {Onboarding} from "./pages/Onboarding";
import Error from "./pages/Error";
import {Home} from "./pages/Home";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";
import UserData from "./session";


// function App() {
function App() {
    try {
        const WebApp = useWebApp();
        const urlParams = new URLSearchParams();
        urlParams.set('data', WebApp.initData);
        UserData.setData(urlParams.toString())
    } catch (e) {
        window.location.href = '/404';
    }

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    {/*<Route path="/" element={<InitialLoading />} />*/}
                    <Route path="/" element={<Home />} />
                    <Route path="/onboard" element={<Onboarding />} />
                    <Route path="/leaderboard" element={<Leaderboard />} />
                    <Route path="/invite-friends" element={<Friends />} />
                    <Route path="/angels" element={<Angels />} />
                    <Route path="/*" element={<Error />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
