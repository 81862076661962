import {FaMedal} from "react-icons/fa6";
import React from "react";
import Navigation from "../components/Navigation";
import '../css/leaderboard.css';
import config from "../config";
import UserData from "../session";
import {FaUser} from "react-icons/fa";


export class Leaderboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current_balance: '-',
            leaderboard_position: 0,
            ranking: [],
            telegram_username: '',
            total_holders: 0
        };
    }

    componentDidMount() {
        fetch(config.server_api + 'leaderboard?' + UserData.getData())
            .then(res => {
                return res.json();
            })
            .then(res => {
                this.setState({
                    current_balance: res.current_balance,
                    leaderboard_position: res.leaderboard_position,
                    ranking: res.ranking,
                    telegram_username: res.telegram_username,
                    total_holders: res.total_holders
                });
            }
        ).catch(err => {
            window.location.href = '/404';
        });
    }

    render () {
        return (
            <div className="leaderboard-page">
                <div className="page-content">
                    <div className="page-content__head">
                        <h1 className="page-content__head-title">Telegram Wall of Fame</h1>
                        <div className="page-content__head-info">
                            <span className="info-avatar"><FaUser/></span>
                            <div className="info-user">
                                <span className="info-user__name">{this.state.telegram_username}</span>
                                <span className="info-user__tokens">{this.state.current_balance} $ANGEL</span>
                            </div>
                            <span className="info-position">#{this.state.leaderboard_position}</span>
                        </div>
                    </div>
                    <div className="holders">
                        <span className="holders__title">{this.state.total_holders} holders</span>
                        {this.state.ranking.map((item, index) =>
                            <div className="holders__item" key={index}>
                                <span className="holders__item-avatar"><FaUser/></span>
                                <span className="holders__item-text">
                                    <span className="holders__item-text__name">{item.name}</span>
                                    <span className="holders__item-text__amount">{item.balance}</span>
                                </span>
                                <span className="holder-position">
                                    {(() => {
                                        if (item.position === 1) {
                                            return (<span className="holder-position__position gold-medal"><FaMedal/></span>)
                                        } else if (item.position === 2) {
                                            return (<span className="holder-position__position silver-medal"><FaMedal/></span>)
                                        } else if (item.position === 3) {
                                            return (<span className="holder-position__position bronze-medal"><FaMedal/></span>)
                                        } else {
                                            return (<span className="holder-position__position">#{item.position}</span>)
                                        }
                                    })()}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <Navigation/>
            </div>
        )
    }
}