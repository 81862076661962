import React, {useState} from "react";
import Navigation from "../components/Navigation";
import '../css/invite-friends.css';
import config from "../config";
import UserData from "../session";
import {FaCommentDots, FaTelegramPlane, FaWhatsapp} from "react-icons/fa";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";


export default function Angels (props) {
    const [list, setList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const webApp = useWebApp();

    if (!loaded) {
        fetch(config.server_api + 'companions?' + UserData.getData())
            .then(res => {
                return res.json();
            })
            .then(res => {
                setLoaded(true)
                setList(res)
            }).catch(err => {
            window.location.href = '/404';
        });
    }

    const lst = list.map((item, index) =>
        <div className="friends__item clean" key={index}>
            <span className="friends__item-avatar">
                <img src={config.server_url + item.personality.avatar_image_web}
                     className="angel-logo" alt="logo"/>
            </span>
            <span className="friends__item-text">
                <span className="friends__item-text__name">{item.personality.name}</span>
                <span className="friends__item-text__amount">{item.subtitle}</span>
            </span>
            {(() => {
                if (item.personality.telegram_url) {
                    return (<span onClick={() => webApp.openTelegramLink(item.personality.telegram_url)} className="holder-position__position friends-links"><FaTelegramPlane/></span>)
                }
            })()}
            {/*{(() => {*/}
            {/*    if (item.personality.whatsapp_url) {*/}
            {/*        return (<span onClick={() => webApp.openTelegramLink('https://wa.me/'+item.personality.whatsapp_url)} className="holder-position__position friends-links"><FaWhatsapp/></span>)*/}
            {/*    }*/}
            {/*})()}*/}
            <span onClick={() => webApp.openLink('https://angel.ai/chat/?next='+item.personality.name)} className="holder-position__position friends-links"><FaCommentDots/></span>
        </div>
    )


    return (
        <div className="invite-friends-page">
            <div className="page-content">
                <div className="friends">
                    <h1 className="page-content__head-title">Meet our Angels</h1>
                </div>
                {lst}
            </div>
            <Navigation/>
        </div>
    );
}