import {Link} from "react-router-dom";
import {FaBullseye} from "react-icons/fa6";
import logo from "../assets/logo-home.png";
import React from "react";
import config from "../config";
import InitialLoading from "./Load";
import Navigation from "../components/Navigation";
import '../css/home.css';
import Notices from "../components/Notices";
import UserData from "../session";
import TasksLists from "../components/Tasks";
import YourRewards from "../components/YourRewards";

export class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currently_loading: true,
            current_balance: null,
            notices: [],
            tasks: [],
            historic: [],
        };
        this.handler = this.handler.bind(this)
    }

    componentDidMount() {
        this.handler();
    }

    handler() {
        this.setState({
            currently_loading: true,
            current_balance: null,
            notices: [],
            tasks: [],
            historic: [],
        });
        fetch(config.server_api + 'home?' + UserData.getData())
            .then(res => {
                return res.json();
            }).then(res => {
            if(res.onboard_complete === false){
                window.location.href = '/onboard';
            }
            this.setState({
                currently_loading: false,
                current_balance: res.current_balance,
                notices: res.notices,
                tasks: res.tasks,
                historic: res.historic,
            });
        }).catch(err => {
            // console.log(err)
            window.location.href = '/404';
        });
    }

    render () {
        if(this.state.currently_loading){
            return (
                <InitialLoading/>
            )
        }
        return (
            <div className="home-page">
                <div className="page-content">
                    <Link to={'/onboard'} className="banner-score"> <FaBullseye/> Your score </Link>
                    <div className="status-member">
                        <div className="status-member__info">
                            <img src={logo} className="angel-logo" alt="logo"/>
                            <span className="reward-points">{this.state.current_balance} <span>$ANGEL</span></span>
                        </div>
                        {/*<Link to=a "/connect-wallet" className="btn btn-full-width btn-primary">Connect wallet</Link>*/}
                    </div>
                    <Notices notices={this.state.notices}/>
                    <TasksLists tasks={this.state.tasks} update={this.handler}/>
                    <YourRewards rewards={this.state.historic}/>
                </div>
                <Navigation/>
            </div>
        );
    }

}