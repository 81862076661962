import React from "react";


export default function InitialLoading() {

    return (
        <div className="onboarding-page">
            <div className="status-member">
                <h1>Loading!</h1>
                <div className="heard-loader"></div>
            </div>
        </div>
    );

}
