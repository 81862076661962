import React from "react";
import '../css/error.css';
import top from "../assets/404_top.png";
import bottom from "../assets/404_bottom.png";


export default function Error() {

    return (
        <div className="error-page">
            <img src={top} className="404-logo" alt="logo"/>
            <h1>Who are you dawn?</h1>
            <img src={bottom} className="404-logo" alt="logo"/>
        </div>
    );

}
