import React, {useState} from "react";
// import {FaMedal, FaReact} from "react-icons/fa6";
import UserData from "../session";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function TasksLists(props) {
    const [lista, setList] = useState(props.tasks);
    const [visible, setVisible] = useState(false);
    const [newPoints, setNewPoints] = useState(0);

    const webApp = useWebApp();

    const requestTaskStatus = (link) => {
        fetch(link + (link.indexOf('?') > -1 ? '&': '?') + UserData.getData())
            .then(res => {
                return res.json();
            }).then(res => {
            if(res.new_points > 0) {
                setVisible(true)
                setNewPoints(res.new_points)
            } else {
                toast.info('Task not complete!', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }).catch(err => {
                window.location.href = '/404';
            }
        );
    };

    const notify = () => {
        if(visible) {
            return (
                <div
                    onClick={(() => {
                        props.update()
                        setVisible(false)
                    })}
                    className="new-points-popup"
                >
                    <div className="content">
                        <span>¡You have won {newPoints} $ANGEL!</span>
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            <div className="tasks">
                <span className="tasks__title">Tasks</span>
                {notify()}
                {lista.map((item, index) =>
                <div className="tasks__item" key={index}>
                    <span className="tasks__item-icon">
                        <img src={item.icon} alt=''/>
                    </span>
                    <span className="tasks__item-text">
                        <span className="tasks__item-text__name">{item.title}</span>
                        {(() => {
                            if (item.link_type === 1) {
                                return (<a href={item.link} className="tasks__item-text__amount">{item.subtitle_text}</a>)
                            } else if (item.link_type === 2) {
                                return (
                                    <span onClick={() => webApp.openLink(item.link)} className="tasks__item-text__amount">
                                        {item.subtitle_text}
                                    </span>
                                )
                            } else if (item.link_type === 3) {
                                return (
                                    <span onClick={() => webApp.openTelegramLink(item.link)} className="tasks__item-text__amount">
                                        {item.subtitle_text}
                                    </span>
                                )
                            } else {
                                return (<span className="tasks__item-text__amount">{item.subtitle_text}</span>)
                            }
                        })()}
                    </span>
                    {(() => {
                        if (item.button_text === 'Complete') {
                            return (<span className="btn btn-small btn-rounded btn-disabled"> {item.button_text} </span>)
                        } else if (item.button_text === 'Check') {
                            return (<span onClick={() => requestTaskStatus(item.reward_link)} className="btn btn-small btn-rounded btn-primary"> {item.button_text} </span>)
                        } else if (item.button_text === 'Start') {
                            if (item.link_type === 1) {
                                return (<span onClick={() => {
                                    lista[index].button_text = 'Check'
                                    setList([...lista])
                                    window.open(item.link, "_blank")
                                }} className="btn btn-small btn-rounded btn-grey"> {item.button_text} </span>)
                            } else if (item.link_type === 2) {
                                return (<span onClick={() => {
                                    lista[index].button_text = 'Check'
                                    setList([...lista])
                                    webApp.openLink(item.link)
                                }} className="btn btn-small btn-rounded btn-grey"> {item.button_text} </span>)
                            } else if (item.link_type === 3) {
                                return (<span onClick={() => {
                                    lista[index].button_text = 'Check'
                                    setList([...lista])
                                    webApp.openTelegramLink(item.link)
                                }} className="btn btn-small btn-rounded btn-grey"> {item.button_text} </span>)
                            }
                            return (<span onClick={() => requestTaskStatus(item.link)} className="btn btn-small btn-rounded btn-grey"> {item.button_text} </span>)
                        }
                    })()}
                </div>
                )}
                <ToastContainer />
            </div>
        </div>

    );
}
