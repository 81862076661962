import React from "react";
import {FaUser} from "react-icons/fa";


export default function InvitedFriends(props) {
    const list = props.props.map((item, index) =>
        <div className="friends__item" key={index}>
            <span className="friends__item-avatar"><FaUser/></span>
            <span className="friends__item-text">
                <span className="friends__item-text__name">{item.name}</span>
            </span>
            <span className="friends-token"> {item.amount} $ANGEL </span>
        </div>
    )

    return (
        <div>
            {list}
        </div>
    );
}
