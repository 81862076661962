import React from "react";
import toast, {Toaster} from "react-hot-toast";
import { FaRegCopy} from "react-icons/fa6";
import friends from "../assets/friends.webp";
import {FaTimes} from "react-icons/fa";
import Navigation from "../components/Navigation";
import '../css/invite-friends.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import config from "../config";
import UserData from "../session";
import InvitedFriends from "../components/InvitedFriends";


export class Friends extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            invites: 0,
            invite_link: '',
            friends: [],
        };

    }

    componentDidMount() {
        fetch(config.server_api + 'friends?' + UserData.getData())
            .then(res => {
                return res.json();
            })
            .then(res => {
                this.setState({
                    invites: res.invites,
                    invite_link: res.invite_link,
                    friends: res.friends,
                });
            }).catch(err => {
                window.location.href = '/404';
            });
    }

    toggleClass = () => {
        this.setState({
            isActive : !this.state.isActive,
        });
    }

    notify = () => {
        toast((t) => (
            <span className="toast-copy">
                <FaRegCopy/>
                Invite link copied
            </span>
        ));
    }

    render () {
        return (
            <div className="invite-friends-page">
                <div className="page-content">
                    <div className="page-content__head">
                        <h1 className="page-content__head-title">Invite friends and get more $ANGEL</h1>
                    </div>
                    <div className="friends-empty">
                        <img src={friends} className="angel-logo" alt="logo"/>
                        <h3>Earn 150 $ANGEL for each friend you invite.</h3>
                    </div>
                    <div className="friends">
                        <span className="friends__title">{this.state.invites} friends</span>
                        <InvitedFriends props={this.state.friends}/>
                    </div>
                    {/*<button className="btn btn-full-width btn-primary" onClick={this.toggleClass}> Invite friends</button>*/}
                    <CopyToClipboard
                        text={this.state.invite_link}
                        onCopy={() => this.notify}>
                        <button className="btn btn-full-width btn-primary" onClick={this.notify}>Invite friends</button>
                    </CopyToClipboard>
                    <div className="friends-empty">
                        <h6>To receive your reward, your friend must complete at least 3 of the tasks that will appear on the mini-app's list</h6>
                    </div>
                </div>
                <div className={this.state.isActive ? 'modal-wrap modal-open' : 'modal-wrap'}>
                    <div className="modal-backdrop" onClick={this.toggleClass}></div>
                    <div className="modal">
                        <div className="modal-body">
                            <div className="modal-header">
                                <span className="modal-header__title">Invite friend</span>
                                <span className="modal-header__close" onClick={this.toggleClass}><FaTimes/></span>
                            </div>
                            <div className="modal-content">
                                <CopyToClipboard
                                    text={this.state.invite_link}
                                    onCopy={() => this.notify}>
                                    <button className="btn btn-full-width btn-primary" onClick={this.notify}>Copy invite link</button>
                                </CopyToClipboard>
                                {/*TODO: REMATAR SHARE!*/}
                                <button className="btn btn-full-width btn-primary"> Share invite link</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Toaster position="bottom-center" reverseOrder={false}
                             toastOptions={{
                                 className: 'toast-copy',
                                 style: {
                                     borderRadius: '10px',
                                     background: '#333',
                                     color: '#fff'
                                 },
                             }}/>
                </div>
                <Navigation/>
            </div>
        );
    }
}