import React from "react";
import {Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";


export default function Notices(props) {

    const webApp = useWebApp();

    let lista = props.notices.map((item, index) =>
        <SwiperSlide key={index}>
            <h5>{item.title}</h5>
            <span className="desc">{item.message}</span>
            <div className="btn-container">
                {(() => {
                    if (item.link_type === 1) {
                        return (<span onClick={() => {
                            window.open(item.link, "_blank")
                        }} className="btn btn-rounded btn-primary"> {item.link_title} </span>)
                    } else if (item.link_type === 2) {
                        return (<span onClick={() => {
                            webApp.openLink(item.link)
                        }} className="btn btn-rounded btn-primary"> {item.link_title} </span>)
                    } else if (item.link_type === 3) {
                        return (<span onClick={() => {
                            webApp.openTelegramLink(item.link)
                        }} className="btn btn-rounded btn-primary"> {item.link_title} </span>)
                    }
                })()}
            </div>
        </SwiperSlide>
    );

    return (
        <div className="angel-swiper">
            <Swiper slidesPerView={'auto'} spaceBetween={16} pagination={{clickable: true,}}
                    modules={[Pagination]} className="swiper-content">
                {lista}
            </Swiper>
        </div>
    );
}
